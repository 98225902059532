import { graphql, Link, PageProps } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Heading } from '../components/Heading'
import { Layout } from '../components/Layout'
import { Section } from '../components/Section'
import SEO from '../components/seo'
import { CollectionsQuery, Edge, Must } from '../types'

const renderItem = ({ node }: Edge<CollectionsQuery, 'allMarkdownRemark'>) => {
  const { id, frontmatter, fields } = node
  const img = frontmatter.image.childImageSharp.fluid
  return (
    <Col key={id}>
      <Link to={fields.slug}>
        <figure>
          <Img fluid={img} alt={frontmatter.title} />
          <figcaption>{frontmatter.title}</figcaption>
        </figure>
      </Link>
    </Col>
  )
}

const CollectionsPage = ({ data }: PageProps<Must<CollectionsQuery>>) => {
  const collections = data.allMarkdownRemark.edges
  return (
    <Layout className="bg-dark text-white">
      <SEO title="Collections" />
      <Container fluid as={Section}>
        <Container>
          <Heading center>Collections</Heading>
          <p className="lead text-center mb-5">
            The reconstruction of textiles and showcasing collection in museums is a way to start a
            discourse about the value of a garment. Slowing down the fashion as well as closing the
            production and recycle cycle is our mission. We focus on translating our sustainable and
            ethical philosophy into visual esthetics to help you develop your personal style and be
            beyond current fashion and temporary trends.
          </p>
        </Container>
        <Row xs={1} lg={2} xl={3} noGutters className="gallery">
          {collections.slice(0, 6).map(renderItem)}
        </Row>
        <Row xs={2} lg={3} xl={4} noGutters className="gallery small">
          {collections.slice(6).map(renderItem)}
        </Row>
      </Container>
    </Layout>
  )
}

export default CollectionsPage

export const pageQuery = graphql`
  query Collections {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/collections/" } }
      limit: 1000
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 640, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fileAbsolutePath
        }
      }
    }
  }
`
